<template>
    <div class="content_container pef_investment">
		<div class="content">
			<div class="banner">
				<h1>투자금융</h1>
			</div>
			<ul class="common_tab">
				<li>
					<router-link to="/product/InvestMezzanine">Mezzanine 투자</router-link>
				</li>
				<li class="current">
					<router-link to="/product/InvestPef">신기술투자</router-link>
				</li>
			</ul>
			<h2 class="title_product">신기술투자</h2>
			<section class="overview"> 
				<h3>개요</h3>
				<ul>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/pef_investment/pef_investment_overview_icon01.svg" alt="Venture Financing" />
							<p>Venture<br />Financing</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/pef_investment/pef_investment_overview_icon02.svg" alt="Inclusive Growth" />
							<p>Inclusive<br />Growth</p>
						</div>
					</li>
					<li>
						<div class="content_wrapper">
							<img src="@/assets/images/sub/pef_investment/pef_investment_overview_icon03.svg" alt="Private Equity" />
							<p>Initial Public Offering<br />& Capital Gain</p>
						</div>
					</li>
				</ul>
				<div class="textBox">
					<p>잠재적 가치를 갖고 있는 신기술사업자에 대한 투자 및 운영자금 지원과 경영 자문, 기업공개(IPO) 등 Consulting Service를 제공하여 기업가치 및 성장을 지원하는 금융상품입니다.</p>
				</div>
			</section>
			<section class="structure">
				<div class="structure_infogp">
					<p><img src="@/assets/images/sub/pef_investment/pef_investment_preparing_icon.svg" alt="준비중"></p>
					<p>본 상품은 출시 준비중입니다.</p>
					<p>문의 : 온라인 상담 또는 02-2047-7100</p>
				</div>	
			</section>
			
			<Online />
		</div>
	</div>
</template>

<script>
import Online from "@/components/Online.vue"

export default {
    name : "InvestPrivate",
	components: { 
		Online
	},
}
</script>

